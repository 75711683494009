export const RECENT_LIST_REQUEST = 'RECENT_LIST_REQUEST'
export const RECENT_LIST_SUCCESS = 'RECENT_LIST_SUCCESS'
export const RECENT_LIST_FAIL = 'RECENT_LIST_FAIL'

export const RECENT_DETAILS_REQUEST = 'RECENT_DETAILS_REQUEST'
export const RECENT_DETAILS_SUCCESS = 'RECENT_DETAILS_SUCCESS'
export const RECENT_DETAILS_FAIL = 'RECENT_DETAILS_FAIL'

export const RECENT_DELETE_REQUEST = 'RECENT_DELETE_REQUEST'
export const RECENT_DELETE_SUCCESS = 'RECENT_DELETE_SUCCESS'
export const RECENT_DELETE_FAIL = 'RECENT_DELETE_FAIL'

export const RECENT_CREATE_REQUEST = 'RECENT_CREATE_REQUEST'
export const RECENT_CREATE_SUCCESS = 'RECENT_CREATE_SUCCESS'
export const RECENT_CREATE_FAIL = 'RECENT_CREATE_FAIL'
export const RECENT_CREATE_RESET = 'RECENT_CREATE_RESET'

export const RECENT_UPDATE_REQUEST = 'RECENT_UPDATE_REQUEST'
export const RECENT_UPDATE_SUCCESS = 'RECENT_UPDATE_SUCCESS'
export const RECENT_UPDATE_FAIL = 'RECENT_UPDATE_FAIL'
export const RECENT_UPDATE_RESET = 'RECENT_UPDATE_RESET'

export const RECENT_TOP_REQUEST = 'RECENT_TOP_REQUEST'
export const RECENT_TOP_SUCCESS = 'RECENT_TOP_SUCCESS'
export const RECENT_TOP_FAIL = 'RECENT_TOP_FAIL'
