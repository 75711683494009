import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { RiMenu3Line, RiCloseLine, RiAccountCircleLine } from "react-icons/ri";
import dmm from "../assets/dmm.PNG";
import { Link } from "react-router-dom";
import { logout } from "../actions/userActions";
import "../css/navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div className="dmv__navbar">
      <div className="dmv__navbar-links">
        <div className="dmv__navbar-links_logo">
          <Link to="/">
            <img src={dmm} alt="dmm" />
          </Link>
        </div>
        <div className="dmv__navbar-links_container">
          <p>
            <Link to="/">Home</Link>
          </p>
          <p>
            <a href="#about">About Us</a>
          </p>
          <p>
            <a href="#services">Services</a>
          </p>
          <p>
            <a href="#recent">Recent Works</a>
          </p>
          <p>
            <a href="#contact">Contact Us</a>
          </p>
        </div>
      </div>

      <div className="nav-items">
        {userInfo ? (
          <NavDropdown
            title={userInfo.name}
            id="username"
            className="text-white"
          >
            <LinkContainer to="/profile">
              <NavDropdown.Item>Profile</NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
          </NavDropdown>
        ) : (
          <Link to="/login" className="nav-link">
            <RiAccountCircleLine color="#fff" size={27} />
          </Link>
        )}
        {userInfo && userInfo.isAdmin && (
          <NavDropdown title="Admin" id="adminmenu" className="text-white">
            <LinkContainer to="/admin/userlist">
              <NavDropdown.Item>Users</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/admin/servicelist">
              <NavDropdown.Item>Services</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to="/admin/recentlist">
              <NavDropdown.Item>Recent Works</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>
        )}
      </div>

      <div className="dmv__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="dmv__navbar-menu_container scale-up-center">
            <div className="dmv__navbar-menu_container-links">
              <p>
                <Link to="/" className="text-white">
                  Home
                </Link>
              </p>
              <p>
                <a href="#about" className="text-white">
                  About Us
                </a>
              </p>
              <p>
                <a href="#services" className="text-white">
                  Services
                </a>
              </p>
              <p>
                <a href="#recent" className="text-white">
                  Recent Works
                </a>
              </p>
              <p>
                <a href="#contact" className="text-white">
                  Contact Us
                </a>
              </p>
            </div>
            <div className="dmv__navbar-menu_container-links-sign"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
