import React from "react";
import "../css/header.css";
import ppl from "../assets/ppl.png";
import logo from "../assets/logo.PNG";

const Header = () => {
  return (
    <div className="dmv__header section__padding" id="home">
      <div className="dmv__header-content">
        <h1 className="gradient__text">
          Unlock your potential with tailored digital solutions.
        </h1>
        <p>
          Welcome to DMVTec, where we're passionate about crafting innovative
          web solutions to help businesses thrive online. Our team of skilled
          developers, designers, and digital marketers collaborates to create
          visually appealing, functional websites, web applications, and
          e-commerce platforms that drive success.
        </p>

        <div className="dmv__header-content__input">
          <input type="email" placeholder="Your email address" />
          <button type="button">Get Started</button>
        </div>

        <div className="dmv__header-content__ppl">
          <img src={ppl} alt="ppl" />
          <p>1200 people requested acess a visit in the last 24 hours</p>
        </div>
      </div>
      <div className="dmv__header-image">
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
};

export default Header;
