import React from 'react';
import dmm from "../assets/dmm.PNG";
import "../css/footer.css";
import { RiCopyrightLine } from "react-icons/ri";

import "../css/service.css";

const Footer = () => {
  return (
    <div>
      <div className="wash__footer section__padding">
        <div className="wash__footer-heading">
          <h2 className="gradient__text">
            Boosting Your Business with Tailor-Made Solutions
          </h2>
        </div>

        <div className="wash__footer-btn">
          <a href="#contact">
            <p>Request Early Access</p>
          </a>
        </div>

        <div className="wash__footer-links">
          <div className="wash__footer-links_logo">
            <img src={dmm} alt="dmm" />
            <p>
              DMVTec, <br /> All Rights Reserved
            </p>
          </div>
          <div className="wash__footer-links_div">
            <h4>Follow us:</h4>
            <p>
              <i className="fab fa-instagram"></i>
              instagram
            </p>
            <p>
              <i className="fab fa-facebook-f"></i> Facebook
            </p>
            <p>
              <i className="fab fa-twitter"></i> Twitter
            </p>
          </div>
          <div className="wash__footer-links_div">
            <h4>Company:</h4>
            <p>Terms & Conditions </p>
            <p>Privacy Policy</p>
            <p>
              <a href="#contact">Contact Us</a>
            </p>
          </div>
          <div className="wash__footer-links_div">
            <h4>Get in touch:</h4>
            <p>DMVTec, inc</p>
            <p>443-630-1793</p>
            <p>zelalem1111@gmail.com</p>
          </div>
        </div>

        <div className="wash__footer-copyright">
          <p>
            Copyright <RiCopyrightLine color="#fff" size={15} /> DMVTec-2023.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
