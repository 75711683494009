import {
  RECENT_LIST_REQUEST,
  RECENT_LIST_SUCCESS,
  RECENT_LIST_FAIL,

  RECENT_DETAILS_REQUEST,
  RECENT_DETAILS_SUCCESS,
  RECENT_DETAILS_FAIL,

  RECENT_DELETE_REQUEST,
  RECENT_DELETE_SUCCESS,
  RECENT_DELETE_FAIL,

  RECENT_CREATE_REQUEST,
  RECENT_CREATE_SUCCESS,
  RECENT_CREATE_FAIL,
  RECENT_CREATE_RESET,

  RECENT_UPDATE_REQUEST,
  RECENT_UPDATE_SUCCESS,
  RECENT_UPDATE_FAIL,
  RECENT_UPDATE_RESET,

  RECENT_TOP_REQUEST,
  RECENT_TOP_SUCCESS,
  RECENT_TOP_FAIL,
} from "../constants/recentConstants";

export const recentListReducer = (state = { recents: [] }, action) => {
  switch (action.type) {
    case RECENT_LIST_REQUEST:
      return { loading: true, recents: [] };

    case RECENT_LIST_SUCCESS:
      return {
        loading: false,
        recents: action.payload,
      };

    case RECENT_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const recentDetailsReducer = (state = { recent: [] }, action) => {
  switch (action.type) {
    case RECENT_DETAILS_REQUEST:
      return { loading: true, ...state };

    case RECENT_DETAILS_SUCCESS:
      return {
        loading: false,
        recent: action.payload,
      };

    case RECENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const recentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case RECENT_DELETE_REQUEST:
      return { loading: true };

    case RECENT_DELETE_SUCCESS:
      return { loading: false, success: true };

    case RECENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const recentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case RECENT_CREATE_REQUEST:
      return { loading: true };

    case RECENT_CREATE_SUCCESS:
      return { loading: false, success: true, recent: action.payload };

    case RECENT_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case RECENT_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const recentUpdateReducer = (state = { recent: {} }, action) => {
  switch (action.type) {
    case RECENT_UPDATE_REQUEST:
      return { loading: true };

    case RECENT_UPDATE_SUCCESS:
      return { loading: false, success: true, recent: action.payload };

    case RECENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case RECENT_UPDATE_RESET:
      return { recent: {} };

    default:
      return state;
  }
};


export const recentTopRatedReducer = (state = { recents: [] }, action) => {
  switch (action.type) {
      case RECENT_TOP_REQUEST:
          return { loading: true, recents: [] }

      case RECENT_TOP_SUCCESS:
          return { loading: false, recents: action.payload }

      case RECENT_TOP_FAIL:
          return { loading: false, error: action.payload }

      default:
          return state
  }
}