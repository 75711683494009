import React from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Brand from "./components/Brand";
import About from "./components/About";
import ContactUs from "./components/ContactUs";

import ServiceScreen from "./screens/ServiceScreen";
import DetailScreen from "./screens/DetailScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ServiceListScreen from "./screens/ServiceListScreen";
import ServiceEditScreen from "./screens/ServiceEditScreen";

import ScreenRecentDetails from "./screens/ScreenRecentDetails";
import RecentListScreen from "./screens/RecentListScreen";
import RecentEditScree from "./screens/RecentEditScree";

function App() {
  return (

    <Router>
      <Navbar />
      <Header />
      <Brand />
      <About />
      <main className="py-3">
        <Container>
          <Switch>
            <Route path="/" exact component={ServiceScreen} />
            <Route path="/login" component={LoginScreen} />
            <Route path="/register" component={RegisterScreen} />
            <Route path="/profile" component={ProfileScreen} />

            {/* Admin Routes */}
            <Route path="/admin/userlist" component={UserListScreen} />
            <Route path="/admin/user/:id/edit" component={UserEditScreen} />
            <Route path="/admin/servicelist" component={ServiceListScreen} />
            <Route
              path="/admin/service/:id/edit"
              component={ServiceEditScreen}
            />
            <Route path="/admin/recentlist" component={RecentListScreen} />
            <Route path="/admin/recent/:id/edit" component={RecentEditScree} />

            <Route path="/service/:id" component={DetailScreen} />
            <Route path="/recent/:id" component={ScreenRecentDetails} />
          </Switch>
        </Container>
      </main>
      <ContactUs />
      <Footer />
    </Router>
  );
}

export default App;
