import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  serviceListReducer,
  serviceDetailReducer,
  serviceDeleteReducer,
  serviceCreateReducer,
  serviceUpdateReducer,
} from "./reducers/serviceReducers";
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
} from "./reducers/userReducers";
import {
  recentListReducer,
  recentDetailsReducer,
  recentDeleteReducer,
  recentCreateReducer,
  recentUpdateReducer,
  recentTopRatedReducer,
} from "./reducers/recentReducers";

const reducer = combineReducers({
  serviceList: serviceListReducer,
  serviceDetails: serviceDetailReducer,
  serviceDelete: serviceDeleteReducer,
  serviceCreate: serviceCreateReducer,
  serviceUpdate: serviceUpdateReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,

  recentList: recentListReducer,
  recentDetails: recentDetailsReducer,
  recentDelete: recentDeleteReducer,
  recentCreate: recentCreateReducer,
  recentUpdate: recentUpdateReducer,
  recentTopRated: recentTopRatedReducer,


});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
