import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Image,
  ListGroup,
  Button,
  Card,
  Form,
} from "react-bootstrap";
import { listRecentDetails } from '../actions/recentActions'
const DetailScreen = ({ match }) => {
  const dispatch = useDispatch();
  const recentDetails = useSelector((state) => state.recentDetails);
  const { error, loading, recent } = recentDetails;
  useEffect(() => {
    dispatch(listRecentDetails(match.params.id));
  }, [dispatch, match]);

  return (
    <div>
      <Link to="/" className="btn btn-dark my-3 ">
        Go back
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Row>
          <Col md={6}>
            <Image src={recent.image} alt={recent.title} fluid />
          </Col>
          <Col md={6}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h3>{recent.title}</h3>
              </ListGroup.Item>

              <ListGroup.Item>
                Description: {recent.description}
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DetailScreen;
