import axios from "axios";

import {
  RECENT_LIST_REQUEST,
  RECENT_LIST_SUCCESS,
  RECENT_LIST_FAIL,

  RECENT_DETAILS_REQUEST,
  RECENT_DETAILS_SUCCESS,
  RECENT_DETAILS_FAIL,

  RECENT_DELETE_REQUEST,
  RECENT_DELETE_SUCCESS,
  RECENT_DELETE_FAIL,

  RECENT_CREATE_REQUEST,
  RECENT_CREATE_SUCCESS,
  RECENT_CREATE_FAIL,

  RECENT_UPDATE_REQUEST,
  RECENT_UPDATE_SUCCESS,
  RECENT_UPDATE_FAIL,

  RECENT_TOP_REQUEST,
  RECENT_TOP_SUCCESS,
  RECENT_TOP_FAIL,
} from "../constants/recentConstants";

export const listRecents = () => async (dispatch) => {
  try {
    dispatch({ type: RECENT_LIST_REQUEST });

    const { data } = await axios.get("/api/recents");

    dispatch({
      type: RECENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RECENT_LIST_FAIL,
      payload: error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listTopRecents = () => async (dispatch) => {
    try {
        dispatch({ type: RECENT_TOP_REQUEST })

        const { data } = await axios.get(`/api/recents/top/`)

        dispatch({
            type: RECENT_TOP_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RECENT_TOP_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listRecentDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: RECENT_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/recents/${id}`);

    dispatch({
      type: RECENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RECENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteRecent = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RECENT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`/api/recents/delete/${id}/`, config);

    dispatch({
      type: RECENT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: RECENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createRecent = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: RECENT_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/recents/create/`, {}, config);
    dispatch({
      type: RECENT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RECENT_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateRecent = (recent) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RECENT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/recents/update/${recent.id}/`,
      recent,
      config
    );
    dispatch({
      type: RECENT_UPDATE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: RECENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RECENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
