import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { listRecents } from "../actions/recentActions";
import '../css/recent.css'
const ServiceCarousel = () => {
  const dispatch = useDispatch();
  const recentList = useSelector((state) => state.recentList);
  const { error, loading, recents } = recentList;

  useEffect(() => {
    dispatch(listRecents());
  }, [dispatch]);

  return (
    <div className="recent-carousel" id="recent">
      <h1>Recent Works</h1>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Carousel pause="hover" className="bg-light">
          {recents.map((recent) => (
            <Carousel.Item key={recent.id} className="carousel-item">
              <Link to={`/recent/${recent.id}`}>
                <Image
                  src={recent.image}
                  alt={recent.title}
                  fluid
                  className="carousel-image"
                />
                <Carousel.Caption className="carousel-caption">
                  <h4 className="recent-title">{recent.title}</h4>
                  <p className="recent-url">{recent.url}</p>
                </Carousel.Caption>
              </Link>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default ServiceCarousel;
