import React from "react";
import "../css/features.css";
const About = () => {
  return (
    <div className="dmv__wht_about section__padding" id="about">
      <div className="dmv__wht_about-content">
        <h1 className="gradient__text">About Us</h1>
        <p>
          We are dedicated to providing innovative and reliable software
          solutions to our clients. With a focus on cutting-edge technology and
          a team of highly skilled professionals, we strive to exceed our
          clients' expectations by delivering customized solutions that meet
          their specific needs. Our goal is to help our clients optimize their
          operations, enhance their productivity, and achieve their business
          objectives through the power of software.
        </p>
        <br />
      </div>
    </div>
  );
};

export default About;
