import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Image,
  ListGroup,
} from "react-bootstrap";
import { listServiceDetails } from "../actions/serviceActions";
const DetailScreen = ({ match }) => {
  const dispatch = useDispatch();
  const serviceDetails = useSelector((state) => state.serviceDetails);
  const { error, loading, service } = serviceDetails;
  useEffect(() => {
    dispatch(listServiceDetails(match.params.id));
  }, [dispatch, match]);

  return (
    <div >
      <Link to="/" className="btn btn-dark my-3 ">
        Go back
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Row>
          <Col md={6}>
            <Image src={service.image} alt={service.name} fluid />
          </Col>
          <Col md={6}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h3>{service.name}</h3>
              </ListGroup.Item>

              <ListGroup.Item>
                {service.description}
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DetailScreen;
