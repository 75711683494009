import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Service = ({ service }) => {
  return (
    <div >
      <Card className="my-3 p-3 rounded">
        <Link to={`/service/${service.id}`}>
          <Card.Img src={service.image} />
        </Link>
        <Card.Body>
          <Link to={`/service/${service.id}`}>
            <Card.Title as="div">
              <strong>{service.name}</strong>
              <p>{service.lastName}</p>
            </Card.Title>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Service;
