import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";
import { listRecentDetails, updateRecent } from "../actions/recentActions";
import { RECENT_UPDATE_RESET } from "../constants/recentConstants";

function RecentEditScree({ match, history }) {
  const recentId = match.params.id

  const [title, setTitle] = useState('')
  const [image, setImage] = useState('')
  const [url, setUrl] = useState('')
  const [description, setDescription] = useState('')
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const recentDetails = useSelector(state => state.recentDetails);
  const { error, loading, recent } = recentDetails;

  const recentUpdate = useSelector(state => state.recentUpdate);
  const {error: errorUpdate, loading: loadingUpdate, success: successUpdate} = recentUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: RECENT_UPDATE_RESET })
      history.push("/admin/recentlist")
    } else {
      if (!recent.title || recent.id !== Number(recentId)) {
        dispatch(listRecentDetails(recentId))
      } else {
        setTitle(recent.title)
        setImage(recent.image)
        setUrl(recent.url)
        setDescription(recent.description)
      }
    }
  }, [dispatch, recent, recentId, history, successUpdate])

  const handleGoBack = () => {
    // Clear the form state
    setTitle("")
    setImage("")
    setUrl("")
    setDescription("")
    history.push("/admin/recentlist")
  };

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateRecent({
        id: recentId,
        title,
        image,
        url,
        description,
      })
    )
  }

  // const uploadFileHandler = async (e) => {
  //   const file = e.target.files[0]
  //   const formData = new FormData()

  //   formData.append("image", file);
  //   formData.append("recentid", recentId);

  //   setUploading(true)

  //   try {
  //     const config = {
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       }
  //     }

  //     const { data } = await axios.post(
  //       "/api/recents/upload/",
  //       formData,
  //       config
  //     )

  //     setImage(data)
  //     setUploading(false)

  //   } catch (error) {
  //     setUploading(false)
  //   }
  // }

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]; // Get the selected file
    const formData = new FormData(); // Create a FormData object
  
    formData.append("image", file); // Append the file to the FormData object
    formData.append("recentid", recentId);
  
    setUploading(true);
  
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
  
      const { data } = await axios.post(
        "/api/recents/upload/",
        formData,
        config
      );
      setImage(data);
      setUploading(false);
    } catch (error) {
      setUploading(false);
      // Handle the error, e.g., display an error message to the user
    }
  };

  return (
    <div>
      <Link to="/admin/recentlist" onClick={handleGoBack}>
        Go Back
      </Link>

      <FormContainer>
        <h1>Edit Recent</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}

        {loading ? <Loader /> : error ? <Message variant="danger">{error}</Message>
         : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></Form.Control>
            </Form.Group>

            {/* <Form.Group controlId="image">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                placeholder="Enter image"
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>

              <Form.File
                id="image-file"
                label="Choose File"
                custom
                onChange={uploadFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group> */}

            <Form.Group controlId="image">
              <Form.Label>Image</Form.Label>
              <Form.File
                id="image-file"
                label="Choose File"
                custom
                onChange={uploadFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId="url">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </div>
  )
}

export default RecentEditScree;
