import React, { useState, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";

import { listRecents, deleteRecent, createRecent
 } from '../actions/recentActions'
import { RECENT_CREATE_RESET } from '../constants/recentConstants'

function RecentListScreen({ history, match }) {
  const dispatch = useDispatch();

  const recentList = useSelector((state) => state.recentList);
  const { loading, error, recents } = recentList;

  const recentDelete = useSelector((state) => state.recentDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = recentDelete;

  const recentCreate = useSelector((state) => state.recentCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    recent: createdRecent,
  } = recentCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let keyword = history.location.search

  useEffect(() => {
    dispatch({ type: RECENT_CREATE_RESET });

    if (!userInfo.isAdmin) {
        history.push('/login')
    }

    if (successCreate) {
        history.push(`/admin/recent/${createdRecent.id}/edit`)
    } else {
        dispatch(listRecents(keyword))
    }

  }, [dispatch, history, userInfo, successDelete, successCreate, createdRecent, keyword]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this Product?")) {
      // del
      dispatch(deleteRecent(id));
    }
  };

  const createRecentHandler = () => {
    // create product
    dispatch(createRecent())
  };

  return (
    <div>
      <Row className="align-items-center">
        <Col>
          <h1>Recent works</h1>
        </Col>
        <Col className="text-right">
          <Button className="my-3" onClick={createRecentHandler}>
            <i className="fas fa-plus"></i> Create Recent
          </Button>
        </Col>
      </Row>

      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}

      {loadingCreate && <Loader />}
      {errorCreate && <Message variant="danger">{errorCreate}</Message>}

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>URL</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {recents.map(recent => (
              <tr key={recent.id}>
                <td>{recent.id}</td>
                <td>{recent.title}</td>
                <td>{recent.url}</td>

                <td>
                  <LinkContainer to={`/admin/recent/${recent.id}/edit`}>
                    <Button variant="light" className="btn-sm">
                      <i className="fas fa-edit"></i>
                    </Button>
                  </LinkContainer>

                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(recent.id)}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </div>

      )}
    </div>
  );
}

export default RecentListScreen;
