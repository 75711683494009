import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';

import './bootstrap.min.css';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

// ReactDOM.createRoot(document.getElementById('root')).render(
//   <Provider store={store}>
//     <App />
//   </Provider>
// );

// reportWebVitals();

// import React from 'react';
// import ReactDOM from 'react-dom';

// import { Provider } from 'react-redux';
// import store from './store';
// import { createRoot } from 'react-dom/client'; // Update this line

// import './bootstrap.min.css';
// import App from './App';
// import './index.css';
// import reportWebVitals from './reportWebVitals';

// createRoot(document.getElementById('root')).render(
//   <Provider store={store}>
//     <App />
//   </Provider>
// );
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();