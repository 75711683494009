import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";
import "../css/ContactUs.css";

const ContactUs = () => {
  const formRef = useRef();
  const [isSent, setIsSent] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      await emailjs.sendForm(
        "service_omdou8o",
        "template_pzfi01d",
        formRef.current,
        "2pVUsGS09GOoZSCEp"
      );

      setIsSent(true);
      formRef.current.reset(); // Clear form fields
    } catch (error) {
      console.error("Email error:", error.text);
    }
  };

  return (
    <div className="dmv__contact section__padding" id="contact">
      <h1 className="gradient__text">Get in Touch</h1>
      {isSent && <span className="success-message" color="#fff">Message sent successfully!</span>}
      <p>Please fill out the form below to get in touch with us. We'll get back to you as soon as possible.</p>
      <form className="contact-form" ref={formRef} onSubmit={sendEmail}>
        <FormRow label="Name" type="text" id="user_name" name="user_name" required />
        <FormRow label="Email" type="email" id="user_email" name="user_email" required />
        <FormRow label="Message" type="textarea" id="message" name="message" required />
        <div className="form-row">
          <input type="submit" value="Send" />
        </div>
      </form>
    </div>
  );
};

const FormRow = ({ label, type, id, name, required }) => {
  const TagName = type === "textarea" ? "textarea" : "input";

  return (
    <div className="form-row">
      <label htmlFor={id}>{label}: </label>
      <TagName id={id} name={name} required={required} />
    </div>
  );
};

export default ContactUs;