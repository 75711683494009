import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  Row,
  Col,
} from "react-bootstrap";
import Service from "../components/Service";
import { listServices } from "../actions/serviceActions";
import ServiceCarousel from './ServiceCarousel'
import "../css/service.css";

const ServiceScreen = () => {
  const dispatch = useDispatch();
  const serviceList = useSelector((state) => state.serviceList);
  const { error, loading, services } = serviceList;

  useEffect(() => {
    dispatch(listServices());
  }, [dispatch]);
  return (
    <div id="services">
      <h1>Services</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <Row>
            {services.map((service) => (
              <Col key={service.id} sm={12} md={6} lg={4} xl={3}>
                <Service service={service} />
              </Col>
            ))}
          </Row>
        </div>
      )}
      {<ServiceCarousel/>}
    </div>
  );
};

export default ServiceScreen;
